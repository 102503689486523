/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom styles here */

/* All tables shall be striped. */
tr:not(:nth-child(even)) {
  background-color: #EBEBEB;
}

/* Remove all table borders. */
/* table {
  border-collapse: collapse;
} */

/* Overwrite background color for ios app. */
:root {
  --f7-page-bg-color: white;
}

/* UI handling for text input fields. */
input[type=text] {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 1px;
  border: 1px solid #CCCCCC;
  resize: vertical;
  background-color: rgba(192, 199, 226, 0.7);
}

input[type=text]:hover {
  border: 2px solid black;
  margin: 0px;
}

input[type=text]:focus {
  background-color: transparent;
  margin: 1px;
}

input[type=text]:focus:hover {
  background-color: transparent;
  border: 2px solid black;
  margin: 0px;
}

/* Inputs with problems / missing values. */
input[type=text].input-problem {
  border: 2px dashed red;
  margin: 0px;
}

input[type=text].input-problem:hover {
  margin: 0px;
}

input[type=text].input-problem:focus:hover {
  border: 2px dashed red;
  margin: 0px;
}

/* On popups we want the original color. */
.success-order-popup > .page > .navbar > .navbar-bg{
  background-color: #F7F7F7;
}

.error-popup > .page > .navbar > .navbar-bg{
  background-color: #F7F7F7;
}

/* Make back link black */
.icon-back {
  color: black;
}

/* Handle printing of the page. */

@media print{
  .noprint{
      display:none !important;
  }

  .mainBlock{
    margin-top: -20px;
    margin-bottom: 0px !important;
    background-color: white !important;
  }

  .page-content {
    padding: 0px !important;
  }

  body {
    font: 11pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.1;
  }

  .page, .page-content, html, body, .framework7-root, .views, .view {
    height: auto !important;
  }

  html, body, .framework7-root, .views, .view {
      overflow: visible !important;
      overflow-x: visible !important;
  }

  .page.page-previous {
      display: none;
  }

  @page { 
    size: A4; /* "Force" this for consistency. */
    margin: 1.6cm; 
  }
}